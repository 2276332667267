<!-- Base de Cabecera del Finder -->

<template>
  <div :class="cfg.class" :style="cfg.style">
    <!-- Título -->
    <span>{{ titulo }}</span>

    <!-- Botón para cerrar la ventana modal -->
    <v-btn v-if="cfg.closable" v-bind="$cfe.btn.cerrar" @click="cerrar_form()">
      <v-icon>{{ $cfe.btn.cerrar.icono }}</v-icon>
    </v-btn>
  </div>
</template>

<script>
  export default {
    props: {
      cfg: { type: Object, default: () => undefined },
      titulo: { type: String, default: '' },
    },

    methods: {
      cerrar_form() {
        this.$emit("onEvent", { accion:'cerrar' });
      }
    }
  };
</script>
