<!-- Componente Template Dual (Modal o directo) -->

<template>

  <div class="dualTemplate">
    <div v-if="dualTipo=='modal'">
      <v-dialog
        content-class="modal"
        v-model="showWindow"
        v-bind="$attrs">
          <div class="centrado">
            <slot name="controles"></slot>
          </div>
      </v-dialog>
    </div>
    
    <div v-else>
      <slot name="controles"></slot>
    </div>

  </div>
</template>



<script>

  export default {
    props: {
      dualTipo: { type: String, default: 'modal' },
      modal: { type: Boolean, default: true },
      storeName: { type: String, default: '' }
    },  


    computed: {
      showWindow: {
        get() {
          return this.modal;          
        },

        set(val) {
          console.log('Set dualTemplate: ', this.storeName, this.modal, val);
          if (!this.storeName || !this.$store[this.storeName]) return;
          this.$store.commit(this.storeName + '/modalSet', val);          
        }
      },

    }
  }

</script>

