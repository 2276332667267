<!-- Mto particular de Usuarios Web-->

<template>
  <div class="usuwebM" v-if="loaded">    
    <dualTemplate
      :dualTipo="sync? '':'modal'"
      :modal="modal"
      :storeName="storeRaiz"
       persistent>
      
        <template v-slot:controles="{}">

          <div>
            
            <!-- Cabecera -->
            <div class="cabecera">     
              <baseHeader
                :cfg="$cfe.headers.mto"
                :titulo='$store.state[storeName].titulo'                
                @onEvent="eventHeader">
              </baseHeader>
            </div>  
            
            <v-sheet :elevation="4">
              <div class="contenedor" style="width:300px"></div>      
            
                <!-- Botones Mto -->
                <div v-if="!readOnly" class="d-flex justify-left">

                  <!-- Mto -->            
                  <baseBtraMto
                    :perm="permMto"
                    :modulo="btMtoCfg" 
                    :estado="estado"
                    @onEvent="execAccion">        
                  </baseBtraMto>

                </div>
              
                <!-- Controles del Mto -->
                <div class="ctrls" style="margin-top:10px">
                  <div class="conflex" style="margin-left:10px">
                    <div class="cab ">DATOS</div>
                    <div style="color:steelblue;padding-left:20px">{{ ct.id[2] }}</div>
                  </div>

                  <v-sheet v-bind="$cfg.styles.marco">
                    <div style="display:flex">
                      <v-switch
                        style="width:10%;margin:0;padding:0"
                        v-model="ct.activo[2]"
                        true-value="1"
                        false-value="0"
                        color="green"
                        :readonly="noEdit">
                      </v-switch>
                      
                      <uiText
                        style="width:70%"
                        v-model="ct.usu[2]"
                        :label="ct.usu[1]"
                        :disabled=true>                      
                      </uiText>
                    
                      <v-select
                        style="width:20%"
                        v-bind="$select"
                        v-model="ct.nivel[2]"
                        :label="ct.nivel[1]"
                        :items="itemsNiveles"
                        :disabled="noEdit"
                        item-value="id"
                        item-text="label">              
                      </v-select>
                    </div> 

                    <uiText
                      style="width:100%"
                      v-model="ct.name[2]"
                      :label="ct.name[1]"
                      :disabled="noEdit">                      
                    </uiText>

                    <uiMultiple     
                      style="width:100%"            
                      :ctrl="ct.cta_id"
                      :ct="ct"                  
                      :disabled="noEdit">                                        
                    </uiMultiple>
                      
                    <div style="display:flex">
                      <uiText                       
                        v-model="ct.tlf1[2]"
                        :label="ct.tlf1[1]"
                        :disabled="noEdit">                      
                      </uiText>
                     
                     <uiText
                        v-model="ct.mov1[2]"
                        :label="ct.mov1[1]"
                        :disabled="noEdit">                      
                      </uiText>
                    </div>

                    <uiText
                      style="width:100%"
                      v-model="ct.email[2]"
                      :label="ct.email[1]"
                      :disabled="noEdit">                      
                    </uiText>

                    <uiObs3                       
                      :record="record"
                      :schema="ct"
                      :schemaComponente="ct.obs"
                      :edicion="!noEdit">                       
                    </uiObs3>
                  
                    <div style="display:flex">
                      <uiPass                        
                        :ct="ct.pass"
                        :disabled="noEdit">
                      </uiPass>
                      
                      <uiDate 
                        v-model="ct.acceso[2]" 
                        :label="ct.acceso[1]" 
                        type="datetime-local"
                        :disabled=true>
                      </uiDate>                      
                    </div>

                    <uiDate 
                      style="width:200px"
                      v-model="ct.fhalta[2]" 
                      :label="ct.fhalta[1]" 
                      type="datetime-local"
                      :disabled="noEdit">
                    </uiDate>                
                  </v-sheet>
                </div>
            </v-sheet>            
          </div>
        </template>
    </dualTemplate>
  </div>
</template>



<script>

  import { mixM } from "@/mixins/mixM.js";  
  import baseHeader from "@/base/baseHeader";
  import baseBtraMto from "@/base/baseBtraMto";
  import dualTemplate from "@/components/dualTemplate";
         
  export default {
    mixins: [mixM],
    components: { baseHeader, baseBtraMto, dualTemplate },
    props: {},

    data() {
      return {
        stIni: {
          api: "usuwebM",
          name:"usuwebM",
          titulo:"Mantenimiento USUARIOS WEB",
          recordAccess:"local",
          mView:'usuwebM',
          pView:[]
        },
       
        itemsNiveles: [],
        tipo:0,
      };
    },


    methods: {
      iniDataParticular() {
        console.log("DEV " + this.stIni.api + " ********************** (iniDataParticular)");
         
        // items adicionales  
        //this.itemsTipos=this.$store.state.datos_iniciales.deptos;  
        this.itemsNiveles= [
          { id: "1", label: "1" },
          { id: "2", label: "2" },
          { id: "3", label: "3" },
          { id: "4", label: "4" },
          { id: "5", label: "5" },
          { id: "6", label: "6" },
          { id: "7", label: "7" },
          { id: "8", label: "8" },
          { id: "9", label: "9" },
        ];
       
      },


      iniDataApi() {        
        this.apiArgs.set= ["", "usuarioWeb", { fnt:'set', args: {}, id:null }, "aplica"];          
      },


      validateParticular() {        
        if (!this.ct.cta_id[4].comp.valueAux) {
          this.$root.$alert.open('Debe tener una cuenta', 'error');
          return false;
        }

        return true;
      },


      async stGuardar(recordChanges) {
        let id= this.estado==='editar'? this.record.id : null;
        let param = { apiArg: this.apiArgs.set, args: { id, args:recordChanges } };        
        console.log("stGuardar *************************:", param);

        return await this.$store.dispatch(this.storeName + "/guardar", param);
      }  

    }
  };
</script>
